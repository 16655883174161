import { createStore } from 'vuex'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import company from './modules/company'
import process from './modules/process'
import system from './modules/system'
import security from './modules/security'
import user from './modules/user'
import dashboard from './modules/dashboard'
import system_server from './modules/system_server'
import workstation from './modules/workstation'
import network_hardware from './modules/network_hardware'

export default createStore({
  modules: {
    company,
    process,
    system,
    security,
    user,
    dashboard,
    system_server,
    workstation,
    network_hardware,
  },

  state: {
    server: window.__SERV__,
    link: window.__SERV__,

    auth_headers: localStorage.getItem('token')
      ? { Authorization: `JWT ${localStorage.getItem('token')}` }
      : '',

    // auth_type: 'login', // 'keycloak'
    auth_type: ['login', 'keycloak'].includes(window.__VUE_APP_AUTH_MODE__)
      ? window.__VUE_APP_AUTH_MODE__
      : 'login',

    keycloak_params: {
      url: window.__VUE_APP_KEYCLOAK_URL__,
      client_id: window.__VUE_APP_KEYCLOAK_CLIENT_ID__,
      redirect_url: window.__VUE_APP_KEYCLOAK_REDIRECT_URL__,
    },

    is_sidebar: true,

    info_modal: null,
    info_loading: false,

    company_info_from_api_client: {},

    // * image url will be added from any component with image and used in detailImage component
    image_url: '',

    clients: [],
    clientList: [],

    // processed files after handleSeveralFiles
    // this files will be uploaded to the server
    handled_files: [],
    files_count: 0,

    // count how many files already uploaded
    upload_count: 0,
    error_files_count: 0,

    // image before preview
    selected_image: {},

    preview_image: {},

    // status when get company from shared api
    get_company_loading: false,

    // all users
    users: [],

    default_data: ['Паспорт', 'Заграничный паспорт', 'Водительское удостоверение', 'Полис'],
    default_site_data: ['фио', 'телефон', 'e-mail'],

    process_modal: null,
    system_modal: null,
    com_modal: null,

    // security tool
    protection: {},

    show_notification: false,

    is_sites_checking: true,

    selected_directory_block: 0,
    categories: [],

    // required fields in forms
    required_fields: [
      {'name': 'info', 'fields': [
          'inn', 'ogrn', 'full_name', 'short_name', 'type_of_business', 'manager_name', 'manager_position',
          'contact_phone', 'mail', 'legal_address'
        ]
      },
      {'name': 'siteapp', 'fields': ['sites']},
      {'name': 'commission', 'fields': [
          'o_name', 'o_position', 'o_phone', 'o_mail', 'a_name', 'a_position', 'a_mail', 'a_phone'
        ]
      },
      {'name': 'positions', 'fields': ['positions']},
      {'name': 'processes', 'fields': [
          'responsible_person', 'responsible_person_position', 'responsible_email', 'purpose', 'subjects',
          'have_teens', 'positions', 'sources',
          'auto_decision', 'documents_creating', 'is_systems', 'is_third_parties'
        ]
      },
      {'name': 'systems', 'fields': [
          'description', 'developer', 'responsible_admin', 'type', 'get_data_variants',
          'delete_data_variant', 'data_actions', 'db_country_place', 'system_address'
        ]
      },
      {'name': 'security', 'fields': [
          'description', 'responsible_admin', 'responsible_admin', 'responsible_email'
        ]
      }
    ],

    comments: {},

    toasts: [],

    banner: {
      title: '',
      message: '',
      mail: ''
    },

    // Тэги, выбранные на странице списка форм, должны изменяться после добавления/удаления
    selected_tags: []
  },

  mutations,
  actions,
  getters,
})
